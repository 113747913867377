/** @jsx jsx */
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { jsx, Styled, Flex, Box } from 'theme-ui';
import { Container, Layout, Hero } from '../components';
import Link from '../components/Link';
import RichText from '../components/RichText';
import SmartAppBanner from '../components/SmartAppBanner';
import { getTranslate } from 'react-localize-redux';
import { useSelector, useDispatch } from 'react-redux';
import { changeLocale } from '../state/session';
import { BlogWidget } from '../components/widgets';
import Img from 'gatsby-image';
import { DotIcon } from '../components/Icon';
import BlogCard from '../components/BlogCard';
import capitalize from 'lodash/capitalize';
import Helmet from 'react-helmet';
import Breadcrumb from '../components/Breadcrumb';
import * as analytics from '../utils/analytics';
import { BrowserWrapper, isBrowser } from '../utils';
import { checkLanguageVersion } from '../utils/contentful';
import { filterByChannel } from '../hooks/useBlogPosts';

const BlogInfo = ({ date, tags }) => (
  <Flex sx={{ alignItems: 'center' }}>
    <span>{date}</span>
    {tags && (
      <>
        <DotIcon sx={{ mx: 2 }} />
        {tags.map((tag, i) => (
          <Box key={i}>
            {i > 0 && <span>{','}&nbsp;</span>}
            <Link to="/blog" state={{ tag: tag.toLowerCase() }}>
              {capitalize(tag)}
            </Link>
          </Box>
        ))}
      </>
    )}
  </Flex>
);

const MainContent = ({ title, author, publishTime, tags, image, body, locale, contentful_id }) => {
  return (
    <>
      <Container variant="narrow" sx={{ my: [4, 5] }}>
        <Styled.h1 sx={{ mb: 4, textAlign: 'center', fontSize: [5, 6] }}>{title}</Styled.h1>
        {author ? (
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Img fixed={author.image.fixed} alt={author.name} sx={{ borderRadius: 3 }} />
            <Box sx={{ pl: 3, lineHeight: 'normal' }}>
              <Styled.strong>{author.name}</Styled.strong>
              <br />
              {author.jobTitle && (
                <>
                  {author.jobTitle}
                  <br />
                </>
              )}
              <BlogInfo date={publishTime} tags={tags} />
            </Box>
          </Flex>
        ) : (
          <Flex sx={{ justifyContent: 'center', fontSize: [2, 3] }}>
            <BlogInfo date={publishTime} tags={tags} />
          </Flex>
        )}
      </Container>
      {image && (
        <Container variant="narrow">
          <Img
            fluid={image.fluid}
            sx={{
              borderRadius: 2,
              height: [240, 320, 400],
              mb: [4, 5],
              mx: [0, null, null, -4],
              bg: 'secondary',
              /*
              img: {
                mixBlendMode: 'screen',
              }, // */
            }}
          />
        </Container>
      )}
      {body && (
        <Container variant="text">
          <RichText content={body} locale={locale} parentId={contentful_id} />
        </Container>
      )}
    </>
  );
};

const BlogPost = ({ data, pageContext, location }) => {
  const hideNav = isBrowser && new URLSearchParams(location?.search).get('hideNav') === 'true';
  const translate = getTranslate(useSelector((state) => state.localize));
  const {
    contentful_id,
    title,
    body,
    parentPage,
    image,
    publishTime,
    tags,
    node_locale,
    description,
    keywords,
    updatedAt,
    postType,
    author,
  } = data.contentfulBlogPost;
  const locale = useSelector((state) => state.session.locale);
  const blogPosts = data.allContentfulBlogPost.edges
    .filter(filterByChannel)
    .filter((b) => b?.node?.node_locale.startsWith(locale) && checkLanguageVersion(locale, b?.node?.languages));

  const dispatch = useDispatch();
  useEffect(() => {
    const pageLocale = node_locale && node_locale.includes('-') && node_locale.substring(0, node_locale.indexOf('-'));
    if (pageLocale && pageLocale !== locale) {
      dispatch(changeLocale(pageLocale));
    }
  }, []);
  const section = postType == 'Blog' ? 'blog' : 'news';
  analytics.usePageCategory(section);

  const params = { title, author, publishTime, tags, image, body, locale, contentful_id };

  if (hideNav) {
    return (
      <BrowserWrapper>
        <Styled.root>
          <MainContent {...params} />
        </Styled.root>
      </BrowserWrapper>
    );
  }

  return (
    <Layout
      title={title}
      showAppBanner={true}
      path={pageContext.paht}
      paths={pageContext.paths}
      description={description}
      keywords={keywords}
      locale={node_locale}
      updatedAt={updatedAt}
      image={image ? image.fluid.src : null}
    >
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Article',
            headline: title,
            image: {
              '@type': 'ImageObject',
              url: image ? image.fluid.src : null,
            },
            name: title,
            description: description,
          })}
        </script>
      </Helmet>

      <Container variant="wide" sx={{ mt: 2 }}>
        <Breadcrumb links={[{ to: `/${section}`, title: translate(section) }]}></Breadcrumb>
      </Container>

      <Box>
        <MainContent {...params} />

        <Container sx={{ mb: [4, null, 5] }}>
          {blogPosts && postType == 'Blog' ? (
            <Flex sx={{ justifyContent: 'center', flexWrap: 'wrap', mx: -3 }}>
              {blogPosts
                .filter((post, i) => post.node.postType == 'Blog')
                .slice(0, 3)
                .map((post, key) => (
                  <Box
                    key={key}
                    sx={{
                      flex: 'none',
                      width: ['100%', '50%', '33.33333%'],
                      px: 3,
                      mb: 4,
                    }}
                  >
                    <BlogCard post={post.node} />
                  </Box>
                ))}
            </Flex>
          ) : (
            <BlogWidget posts={blogPosts.filter((post) => post.node.postType != 'Blog')} horizontal />
          )}
        </Container>
      </Box>
    </Layout>
  );
};
export default BlogPost;

export const blogPostQuery = graphql`
  query ($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      contentful_id
      title
      body {
        raw
        references {
          ... on ContentfulAsset {
            ...assetFields
          }
          ... on ContentfulPage {
            ...pageLink
          }
          ... on ContentfulBlogPost {
            ...blogLink
          }
          ... on ContentfulAccordion {
            ...accordionFields
          }
          ... on ContentfulComponent {
            ...componentFields
          }
          ... on ContentfulContact {
            ...contactFields
          }
          ... on ContentfulFrequentlyAskedQuestion {
            ...faqFields
          }
          ... on ContentfulFrequentlyAskedQuestionCategory {
            ...faqCategoryFields
          }
          ... on ContentfulLayoutLink {
            ...layoutLinkFields
          }
          ... on ContentfulPageTable {
            ...tableFields
          }
          ... on ContentfulPageSection {
            ...pageSectionFields
          }
          ... on ContentfulLayoutHighlight {
            ...highlightFields
          }
        }
      }
      image {
        fluid(maxWidth: 1024) {
          ...GatsbyContentfulFluid
        }
      }
      publishTime(formatString: "D.M.Y")
      languages
      postType
      tags
      node_locale
      description
      keywords
      updatedAt
      author {
        name
        jobTitle
        image {
          fixed(width: 64, height: 64, cropFocus: FACE) {
            ...GatsbyContentfulFixed
          }
        }
      }
    }
    allContentfulBlogPost(filter: { slug: { ne: $slug } }, sort: { fields: publishTime, order: DESC }) {
      edges {
        node {
          node_locale
          title
          slug
          postType
          publishTime(formatString: "D.M.Y")
          showAlert
          image {
            fluid(maxWidth: 480) {
              ...GatsbyContentfulFluid
            }
          }
          description
          tags
          keywords
          updatedAt
          languages
          channel
        }
      }
    }
  }
`;
